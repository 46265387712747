@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
   url('./assets/Poppins/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url('./assets/Poppins/Poppins-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
body {
  font-family: 'Poppins';
}
